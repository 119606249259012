<style lang="scss">
.mediadaten {
  .margin-tb-s {
    @media (max-width: $width-lg) {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
  }
  h2 {
    border-left: 0;
    font-size: 160%;
    padding: 10px 0;
    @media (max-width: $width-lg) {
      text-align: center !important;
    }
  }
  h3 {
    font-size: 140%;
    margin-top: 10px;
  }
  .box {
    //border:1px solid red;
    padding-left: 30px;
    padding-right: 30px;
    .row {
      @media (max-width: $width-sm) {
        margin-bottom: 7px;
      }
    }
    img {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: $width-lg) {
        margin: 15px auto !important;
      }
    }
    .quelle-text {
      font-size: 12px;
      margin-top: 10px;
    }
    p {
      margin: 10px 0;
    }
  }
  .border-left-line {
    border-left: 1px solid #000;
    position: relative;
    @media (max-width: $width-lg) {
      border-left: 0;
      border-bottom: 1px solid #000;
      padding-bottom: 50px;
      text-align: center;
    }
    &:after {
      content: '';
      display: block;
      width: 17px;
      height: 17px;
      background: $red;
      border-radius: 50%;
      position: absolute;
      left: -9px;
      margin-top: -9px;
      top: 50%;
      @media (max-width: $width-lg) {
        left: 50%;
        margin-left: -9px;
        bottom: 0;
        top: 100%;
      }
    }
  }
  .border-right-line {
    border-right: 1px solid #000;
    position: relative;
    text-align: right;
    @media (max-width: $width-lg) {
      border-right: 0;
      border-bottom: 1px solid #000;
      padding-bottom: 50px;
      text-align: center;
    }
    &:after {
      content: '';
      display: block;
      width: 17px;
      height: 17px;
      background: $red;
      border-radius: 50%;
      position: absolute;
      right: 0;
      margin-right: -9px;
      margin-top: -9px;
      top: 50%;
      @media (max-width: $width-lg) {
        left: 50%;
        margin-left: -9px;
        bottom: 0;
        top: 100%;
      }
    }
  }
  .border-last {
    @media (max-width: $width-lg) {
      border-right: 0 !important;
      border-bottom: 0 !important;
      &:after {
        display: none;
      }
    }
  }
}
</style>

<template>
  <div class="mediadaten content container">
    <h1 class="text-center">Kommunikationskraft in Zahlen</h1>
    <div class="row">
      <div class="col-lg-6 align-self-center margin-tb-s box">
        <h2 class="text-right">Die meist gelesene Zeitung <span class="breaker"></span>im Süden Österreichs</h2>
        <img src="@/assets/img/img-content/allgemeines/osterreichkarte-zeitung.png" alt="Österreich Karte" class="img-fluid img-right" />
      </div>
      <div class="col-lg-6 align-self-center margin-tb-s box border-left-line">
        <h3>Reichweite Print</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-3 col-12"><strong>9,3 %</strong></div>
          <div class="col-md-6 col-12">National</div>
          <div class="col-md-3 col-12">708.000</div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12"><strong>38,9 %</strong></div>
          <div class="col-md-6 col-12">Steiermark</div>
          <div class="col-md-3 col-12">417.000</div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12"><strong>43,4 %</strong></div>
          <div class="col-md-6 col-12">Kärnten</div>
          <div class="col-md-3 col-12">210.000</div>
        </div>
        <!-- -->
        <h3>Täglich Reichweite Digital</h3>
        <div class="row">
          <div class="col-md-3 col-12">National</div>
          <div class="col-md-6 col-12">296.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>4,4 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12">Steiermark</div>
          <div class="col-md-6 col-12">104.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>10,9 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12">Kärnten</div>
          <div class="col-md-6 col-12">47.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>10,4 %</strong></div>
        </div>
        <h3>Monatliche Reichweite Digital</h3>
        <div class="row">
          <div class="col-md-3 col-12">National</div>
          <div class="col-md-6 col-12">2.085.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>30,8 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12">Steiermark</div>
          <div class="col-md-6 col-12">500.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>52,5 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12">Kärnten</div>
          <div class="col-md-6 col-12">242.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>53,5 %</strong></div>
        </div>

        <h3>Reichweite Digital</h3>
        <!--<div class="row">
          <div class="col-md-3 col-12">National</div>
          <div class="col-md-6 col-12">1.943.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>25,6 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12">Steiermark</div>
          <div class="col-md-6 col-12">519.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>47,5 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-3 col-12">Kärnten</div>
          <div class="col-md-6 col-12">229.000 Unique User</div>
          <div class="col-md-3 col-12"><strong>46,0 %</strong></div>
        </div>-->

        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6">Unique Clients</div>
          <div class="col-md-6 col-6"><strong>3.490.746 </strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Visits</div>
          <div class="col-md-6 col-6"><strong>16.045.981</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Page Impressions</div>
          <div class="col-md-6 col-6"><strong>54.304.493</strong></div>
        </div>
        <!-- -->
        <div class="quelle-text">
          MA 2021, Erhebungszeitraum: Jänner bis Dezember 2021, Reichweite in Leser pro Ausgabe, Basis: National, Steiermark bzw. Kärnten, wochentags. Die Reichweiten unterliegen einer statistischen Schwankungsbreite; siehe dazu http://media-analyse.at/Signifikanz, ÖWA 2021-IV Einzelangebot pro Monat, Grundgesamtheit Internetnutzer. ÖWA Basic, QS 1-3, 2022
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 align-self-center margin-tb-s box order-lg-2">
        <h2>Die auflagenstärkste Kaufzeitung<span class="breaker"></span>in ihrem Verbreitungsgebiet: print &amp; digital</h2>
        <img src="@/assets/img/img-content/allgemeines/zeitung-devices.png" alt="Zeitunge Devices" class="img-fluid" />
      </div>
      <div class="col-lg-6 align-self-center margin-tb-s box border-right-line">
        <h3>Steiermark</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6">Druckauflage</div>
          <div class="col-md-6 col-6"><strong>170.595</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Verkaufte Auflage</div>
          <div class="col-md-6 col-6"><strong>187.361</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Abonnierte Auflage</div>
          <div class="col-md-6 col-6"><strong>182.544</strong></div>
        </div>
        <h3>Kärnten</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6">Druckauflage</div>
          <div class="col-md-6 col-6"><strong>84.776</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Verkaufte Auflage</div>
          <div class="col-md-6 col-6"><strong>88.403</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Abonnierte Auflage</div>
          <div class="col-md-6 col-6"><strong>85.771</strong></div>
        </div>
        <!-- -->
        <div class="quelle-text">ÖAK 2. HJ. 2021, Mo.–Sa., abonnierte und verkaufte Auflage inklusive E-Paper, Abo-Anteil gemessen an der verkauften Auflage der Kleinen Zeitung</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 align-self-center margin-tb-s box">
        <h2 class="text-right">Crossmediale Reichweite unter Entscheidungsträgern</h2>
        <img src="@/assets/img/img-content/allgemeines/crossmediale-reichweite.png" alt="Crossmediale Reichweite" class="img-fluid img-right" />
      </div>
      <div class="col-lg-6 align-self-center margin-tb-s box border-left-line">
        <h3>Steiermark</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6">Kleine Zeitung</div>
          <div class="col-md-6 col-6"><strong>73,6 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Kronen Zeitung</div>
          <div class="col-md-6 col-6"><strong>36,0 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Die Presse</div>
          <div class="col-md-6 col-6"><strong>20,6 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Der Standard</div>
          <div class="col-md-6 col-6"><strong>17,1 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Kurier</div>
          <div class="col-md-6 col-6"><strong>15,9 %</strong></div>
        </div>
        <h3>Kärnten</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6">Kleine Zeitung</div>
          <div class="col-md-6 col-6"><strong>76,3 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Kronen Zeitung</div>
          <div class="col-md-6 col-6"><strong>40,5 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Die Presse</div>
          <div class="col-md-6 col-6"><strong>19,9 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Der Standard</div>
          <div class="col-md-6 col-6"><strong>29,1 %</strong></div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">Kurier</div>
          <div class="col-md-6 col-6"><strong>16,4 %</strong></div>
        </div>
        <p>Der durchschnittliche österreichische Entscheider ist im Schnitt 48 Jahre alt, seit 17,1 Jahren im Unternehmen, verheiratet und hat ein Nettoeinkommen von 4.576,40 Euro im Monat.</p>
        <!-- -->
        <div class="quelle-text">Leseranalyse Entscheidungsträger (LAE) 3.0 2021, Basis: Steiermark und Kärnten</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 align-self-center margin-tb-s box order-lg-2">
        <h2>Reichweite nach Altersgruppen</h2>
        <img src="@/assets/img/img-content/allgemeines/reichweite.png" alt="Reichweite" class="img-fluid" />
      </div>
      <div class="col-lg-6 align-self-center margin-tb-s box border-right-line">
        <div class="row">
          <div class="col-lg-6">
            <!-- -->
            <h3>Steiermark</h3>
            <div class="row">
              <div class="col-md-6 col-6"><strong>14-19</strong></div>
              <div class="col-md-6 col-6">28,2 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>20-29</strong></div>
              <div class="col-md-6 col-6">18,5 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>30-39</strong></div>
              <div class="col-md-6 col-6">25,9 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>40-49</strong></div>
              <div class="col-md-6 col-6">36,9 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>50-59</strong></div>
              <div class="col-md-6 col-6">46,0 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>60-69</strong></div>
              <div class="col-md-6 col-6">46,2 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>70 oder älter</strong></div>
              <div class="col-md-6 col-6">59,7 %</div>
            </div>

            <!-- -->
          </div>
          <div class="col-lg-6">
            <!-- -->
            <h3>Kärnten</h3>
            <div class="row">
              <div class="col-md-6 col-6"><strong>14-19</strong></div>
              <div class="col-md-6 col-6">38,3 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>20-29</strong></div>
              <div class="col-md-6 col-6">31,3 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>30-39</strong></div>
              <div class="col-md-6 col-6">29,3 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>40-49</strong></div>
              <div class="col-md-6 col-6">34,4 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>50-59</strong></div>
              <div class="col-md-6 col-6">47,7 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>60-69</strong></div>
              <div class="col-md-6 col-6">52,0 %</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6"><strong>70 oder älter</strong></div>
              <div class="col-md-6 col-6">59,7 %</div>
            </div>

            <!-- -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 align-self-center margin-tb-s box">
        <h2 class="text-right">Haushaltsnettoeinkommen</h2>
        <img src="@/assets/img/img-content/allgemeines/haushaltseinkommen.png" alt="Haushaltseinkommen" class="img-fluid img-right" />
      </div>
      <div class="col-lg-6 align-self-center margin-tb-s box border-left-line">
        <div class="row">
          <div class="col-lg-6">
            <!-- -->
            <h3>Steiermark</h3>
            <div class="row">
              <div class="col-md-7 col-6"><strong>bis € 1.199</strong></div>
              <div class="col-md-5 col-6">25,9 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 1.200 - 1.799</strong></div>
              <div class="col-md-5 col-6">23,9 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 1.800 - 2.399</strong></div>
              <div class="col-md-5 col-6">34,9 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 2.400 - 2.999</strong></div>
              <div class="col-md-5 col-6">43,4 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 3.000 - 3.999</strong></div>
              <div class="col-md-5 col-6">40,2 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 4.000 od. mehr</strong></div>
              <div class="col-md-5 col-6">45,3 %</div>
            </div>

            <!-- -->
          </div>
          <div class="col-lg-6">
            <!-- -->
            <h3>Kärnten</h3>
            <div class="row">
              <div class="col-md-7 col-6"><strong>bis € 1.199</strong></div>
              <div class="col-md-5 col-6">40,7 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 1.200 - 1.799</strong></div>
              <div class="col-md-5 col-6">31,0 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 1.800 - 2.399</strong></div>
              <div class="col-md-5 col-6">37,4 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 2.400 - 2.999</strong></div>
              <div class="col-md-5 col-6">33,5 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 3.000 - 3.999</strong></div>
              <div class="col-md-5 col-6">44,5 %</div>
            </div>
            <div class="row">
              <div class="col-md-7 col-6"><strong>€ 4.000 od. mehr</strong></div>
              <div class="col-md-5 col-6">53,7 %</div>
            </div>

            <!-- -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 align-self-center margin-tb-s box order-lg-2">
        <h2>Sozialschichten</h2>
        <img src="@/assets/img/img-content/allgemeines/sozialschichten.png" alt="Sozialschichten" class="img-fluid" />
      </div>
      <div class="col-lg-6 align-self-center margin-tb-s box border-right-line border-last">
        <h3>Steiermark</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6"><strong>Schicht A</strong></div>
          <div class="col-md-6 col-6">49,3 %</div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6"><strong>Schicht B</strong></div>
          <div class="col-md-6 col-6">42,8 %</div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6"><strong>Schicht C1/C2</strong></div>
          <div class="col-md-6 col-6">41,7 %</div>
        </div>

        <h3>Kärnten</h3>
        <!-- -->
        <div class="row">
          <div class="col-md-6 col-6"><strong>Schicht A</strong></div>
          <div class="col-md-6 col-6">54,2 %</div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6"><strong>Schicht B</strong></div>
          <div class="col-md-6 col-6">54,1 %</div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6"><strong>Schicht C1/C2</strong></div>
          <div class="col-md-6 col-6">44,4 %</div>
        </div>
        <!-- -->
        <div class="quelle-text ">MA 2021, Erhebungszeitraum Jänner bis Dezember 2021, Basis: National, Steiermark und Kärnten, die angegebenen Werte unterliegen statistischen Schwankungsbreiten, siehe dazu http://mediaanalyse.at/Signifikanz</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
});
</script>
